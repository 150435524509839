import { Table } from "antd";

function SupportFormatTable() {
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Support Format",
      dataIndex: "format",
      key: "format",
    },
  ];
  const data = [
    {
      key: "1",
      type: "Platforms",
      format:
        "Docker, Android, iOS, Ubuntu, openSUSE, Fedora, CentOS, Debian, Vxworks, QNX",
    },
    {
      key: "2",
      type: "File System",
      format:
        "Cramfs, ext, JFFS2, romfs, squashfs, yaffs2, ubifs, Android Sparse",
    },
    {
      key: "3",
      type: "Programming Languages",
      format: "C, C++, C#, Java, JavaScript, Python, Go",
    },
    {
      key: "4",
      type: "CPU Architecture",
      format: "Intel, Power PC, ARM, Sparc, MIPS",
    },
    {
      key: "5",
      type: "Compress Format",
      format:
        "7z, chm, lzip, rzip, lzma ,tar, cpio, lzop, upx, ar(Archive in Unix), gzip, msi, xar, bzip2, zip ,cab, lrzip, rar, arj",
    },
    {
      key: "6",
      type: "File Format",
      format:
        "elf, Android Dex/Odex, APK (Android Application Package), Android Resource, IPA for iOS and iPad OS, Java class files, Windows PE(.exe), bFLT, symbolic links, Linux Kernel, Linux Kernel Module, Linux Shared Object, Java Archive, Intel HEX, SREC, uBoot",
    },
  ];
  return <Table columns={columns} dataSource={data} pagination={false} />;
}
export default SupportFormatTable;
