import axios from "axios";
import ShowMessage from "component/Message/message";

const instanceAAAS = axios.create({
  baseURL: `${process.env.REACT_APP_ACCOUNT_SERVER}/accountServer`,
});
const instanceSecSAM = axios.create({
  baseURL: `${process.env.REACT_APP_SECSAM_SERVER}/secSAM`,
});

export const AAASfetch = async (url = "", data = {}, token = null) => {
  try {
    const response = await instanceAAAS.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: !!token && `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const errorData = error.response.data;

      return errorData;
    } else {
      ShowMessage("error", "FA01");

      return undefined;
    }
  }
};

export const AAASupload = async (url = "", data = {}, token = null) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined) {
      if (key === "file") {
        formData.append(key, value.originFileObj);
      } else {
        formData.append(key, value);
      }
    }
  });

  try {
    const response = await instanceAAAS.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: !!token && `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const errorData = error.response.data;

      return errorData;
    } else {
      ShowMessage("error", "FA02");

      return undefined;
    }
  }
};

export const SecSAMfetch = async (url = "", data = {}, token = null) => {
  try {
    const response = await instanceSecSAM.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: !!token && `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const errorData = error.response.data;

      return errorData;
    } else {
      ShowMessage("error", "FA03");

      return undefined;
    }
  }
};
