import { notification } from "antd";

function OpenNotification(
  title = "",
  description = "",
  type = "", // success info warning error
  placement = "topRight", // topLeft topRight bottomLeft bottomRight
  duration = 6, // (number) second(s)
  width = "320px"
) {
  const openNotification = {
    message: title,
    description: description,
    placement: placement,
    duration: duration,
    style: {
      width: width,
    },
  };

  if (type !== "") notification[type](openNotification);
  else notification.open(openNotification);
}

export default OpenNotification;
