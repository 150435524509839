import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AAASfetch, AAASupload, SecSAMfetch } from "utils/fetchData";
import ShowMessage from "../../component/Message/message";
import FullPageLoading from "component/FullPageLoading/fullPageLoading";
import {
  Grid,
  Card,
  Form,
  Input,
  Button,
  Upload,
  Modal,
  Typography,
} from "antd";
import { MailOutlined, InboxOutlined } from "@ant-design/icons";
import "./exportReport.less";
import { QuestionCircleOutlined } from "@ant-design/icons";
import SupportFormatTable from "./SupportTable/supportTable.js";
const { useBreakpoint } = Grid;
const { Dragger } = Upload;

const Result = ({ succeed = false, email = "" }) => {
  const { t } = useTranslation("pages");

  return succeed ? (
    <>
      <div className="logo">
        <img src="/images/checked_circle.png" alt="check_circle" />
      </div>
      <div className="analyzeResult">{t("analyzeSucText")}</div>
      <div className="analyzeMsg">
        <div>{t("analyzeSucMsg")}&nbsp;</div>
        <div className="email">{email ? email : "-"}</div>
      </div>
    </>
  ) : (
    <>
      <div className="logo">
        <img src="/images/times_circle.png" alt="times_circle" />
      </div>
      <div className="analyzeResult">{t("analyzeFailText")}</div>
      <div className="analyzeMsg">
        <div>
          <div>{t("analyzeFailMsg_1")}</div>
          <div>{t("analyzeFailMsg_2")}</div>
        </div>
      </div>
      <div className="failedEmail">
        <a href="mailto:service@onwardsecurity.com">
          <MailOutlined />
          &nbsp;{t("contactUs")}
        </a>
      </div>
    </>
  );
};

const Invalid = ({ condition = "" }) => {
  const { t } = useTranslation("pages");

  return condition === "notInTrialTime" ? (
    <>
      <div className="logo">
        <img src="/images/alert_triangle.png" alt="alert_triangle" />
      </div>
      <div className="analyzeResult">{t("analyzeNotInTrialTimeText")}</div>
      <div className="analyzeMsg">
        <div>
          <div>{t("analyzeNotInTrialTimeMsg_1")}</div>
          <div>{t("analyzeNotInTrialTimeMsg_2")}</div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="logo">
        <img src="/images/404.png" alt="404" />
      </div>
      <div className="analyzeResult">{t("404Title")}</div>
      <div className="analyzeMsg">{t("404Msg")}</div>
    </>
  );
};

const FileUpload = ({ token, status = "", setStatus = () => {} }) => {
  const { t } = useTranslation(["components", "messages"]);
  const isAnalyzing = status === "ongoing";
  const [isUploading, setUploading] = useState(false);
  const [openFormatTable, setopenFormatTable] = useState(false);
  const normFile = (e) => {
    if (Array.isArray(e)) return e;
    return e && e.fileList;
  };

  const handleAnalyze = (values) => {
    const data = {
      reportName: values.name,
      file: values.file[0],
    };

    setUploading(true);
    AAASupload("createBinaryScan", data, token).then((res) => {
      if (res?.error) {
        setUploading(false);
        ShowMessage("error", res.description, 5);
      } else if (res?.binaryId && res?.reportName) {
        let data = {
          binaryId: res.binaryId.toString(),
          reportName: res.reportName,
        };

        SecSAMfetch("createBinaryAnalysisTestReport", data, token).then(
          (res) => {
            if (res.status) {
              setStatus(res.status);
            } else if (res?.error) {
              ShowMessage("error", res.description, 5);
            }
            setUploading(false);
          }
        );
      }
    });
  };

  return (
    <>
      <Form
        layout="vertical"
        onFinish={handleAnalyze}
        style={{ marginTop: "32px" }}
      >
        <Form.Item
          name={"name"}
          label={t("reportName")}
          rules={[
            { required: true, message: t("error.empty", { ns: "messages" }) },
            {
              pattern: /^[\dA-Za-z _]*$/,
              message: t("error.inputContainRules", {
                rulesString: "(e.g. A-Z, a-z, 0-9, _, Space)",
                ns: "messages",
              }),
            },
            {
              max: 100,
              message: t("error.lengthMax", { max: 100, ns: "messages" }),
            },
            {
              pattern: /^(?:(?!sleep|select).)*$/i,
              message: t("error.excludeString", {
                excludeString: "sleep, select",
                ns: "messages",
              }),
            },
          ]}
        >
          <Input disabled={isUploading || isAnalyzing} />
        </Form.Item>
        <Form.Item
          name={"file"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          label={
            <div className="label">
              {t("uploadBinaryFile")}
              <Button
                type="link"
                icon={<QuestionCircleOutlined />}
                onClick={() => setopenFormatTable(true)}
              >
                {t("Support Format")}
              </Button>
            </div>
          }
          rules={[
            { required: true, message: t("error.empty", { ns: "messages" }) },
          ]}
        >
          <Dragger
            name="file"
            maxCount={1}
            beforeUpload={() => false}
            disabled={isUploading || isAnalyzing}
          >
            <InboxOutlined className="uploadIcon" />
            <div className="uploadDescription">{t("clickDragFile")}</div>
          </Dragger>
        </Form.Item>
        <div className="verifyBtn">
          <Button
            type="primary"
            htmlType="submit"
            loading={isUploading || isAnalyzing}
          >
            {isUploading || isAnalyzing ? t("analyzing") : t("analyze")}
          </Button>
        </div>
      </Form>
      <Modal
        title={
          <Typography level={5}>
            {t("Binary Analysis Support Format")}
          </Typography>
        }
        open={openFormatTable}
        onOk={() => setopenFormatTable(false)}
        onCancel={() => setopenFormatTable(false)}
        footer={null}
        width="1000px"
      >
        <>
          <div className="tableTitle">
            {"Please use compiled files without encryption."}
          </div>
          <SupportFormatTable />
        </>
      </Modal>
    </>
  );
};

export const ExportReport = () => {
  const { t } = useTranslation("pages");

  const screens = useBreakpoint();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);

  const [isValid, setIsValid] = useState(false);
  const [status, setStatus] = useState(null); // ongoing, completed, failed, unauthorized, notInTrialTime
  const [email, setEmail] = useState("");
  const token = searchParams.get("q");

  const cardStyle = {
    width: screens.xl ? "1160px" : "728px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 2px 2px",
  };

  const checkStatus = useCallback(async () => {
    const checkStatusParams = { source: "Binary Analysis" };
    await SecSAMfetch("lastTestReportStatus", checkStatusParams, token).then(
      (res) => {
        if (res?.error) {
          setStatus("failed");
        } else {
          if (res?.status) {
            setStatus(res.status);
          }
          if (res?.mailTo) {
            setEmail(res?.mailTo);
          }
        }
      }
    );
  }, [token]);

  useEffect(() => {
    AAASfetch("verifyAnalysis", undefined, token).then((res) => {
      if (res?.error) {
        switch (res.error) {
          case "405-001":
            setStatus("notInTrialTime");
            setLoading(false);
            break;
          case "406-001":
            setIsValid(true);
            checkStatus().then(() => {
              setLoading(false);
            });
            break;
          default:
            setLoading(false);
        }
      } else {
        setIsValid(true);
        setLoading(false);
      }
    });
  }, [checkStatus, token]);

  useEffect(() => {
    let timer;

    if (isValid && status === "ongoing") {
      timer = setInterval(() => {
        checkStatus();
      }, 3000);
    }

    return () => clearInterval(timer);
  }, [isValid, status, checkStatus]);
  return (
    <div className="exportReportContainer">
      <Card style={cardStyle}>
        {!loading ? (
          isValid ? (
            status === "completed" || status === "failed" ? (
              <Result succeed={status === "completed"} email={email} />
            ) : (
              <>
                <div className="logo">
                  <img
                    src="/images/Title_logo.png"
                    alt="HERCULES & SecSBOM logo"
                    style={{ width: "200px" }}
                  />
                </div>
                <div className="title">{t("binaryAnalysisFreeTrial")}</div>
                <FileUpload
                  token={token}
                  status={status}
                  setStatus={setStatus}
                />
              </>
            )
          ) : (
            <Invalid condition={status} />
          )
        ) : (
          <FullPageLoading />
        )}
      </Card>
    </div>
  );
};
