export const capitalizeFirstLetter = (value) => {
  if (typeof value === "string") {
    const capitalizeFirst = (value) =>
      value.charAt(0).toUpperCase() + value.slice(1);

    if (value.indexOf(" ")) {
      const firstCap = value.split(" ").map((item) => capitalizeFirst(item));
      return firstCap.join(" ");
    }

    return capitalizeFirst(value);
  }
  return "";
};

export const generateRoutes = (value) => {
  if (typeof value === "string") {
    return value.toLowerCase().replace(/\s/g, "-");
  }
  return value;
};

export const convertPathToName = (path) => {
  if (typeof path === "string") {
    return path.replace(/-/g, " ");
  }
  return path;
};

export const getPlural = (value) => {
  if (typeof value === "string") {
    if (value.charAt(value.length - 1) === "y") {
      return `${value.slice(0, -1)}ies`;
    } else {
      return `${value}s`;
    }
  }
  return value;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(
    (window.crypto.getRandomValues(new Uint8Array(1)) / 255) * (max - min + 1) +
      min
  );
};

export const getKeyByValue = (obj, value) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const isEmptyObject = (obj = {}) => {
  return !Object.keys(obj).length;
};

export const getKeyByValueFromObjectArray = (array, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i]?.value === value) return array[i].key;
  }

  return undefined;
};
