import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AAASfetch } from "utils/fetchData";
import { Card, Grid, Button } from "antd";
import ShowMessage from "component/Message/message";
import "./freeTrial.less";
import "../../default.less";

const { useBreakpoint } = Grid;

export const FreeTrial = () => {
  const { t } = useTranslation(["pages", "components", "messages"]);

  const [searchParams] = useSearchParams();

  const token = searchParams.get("q");
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [trialDetail, setTrialDetail] = useState();

  const cardStyle = {
    width: screens.xl ? "1160px" : "728px",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 2px 2px",
  };

  const handleVerify = () => {
    setLoading(true);

    AAASfetch("verifyUser", undefined, token).then((res) => {
      if (res?.trialTime) {
        ShowMessage("success", t("success.verifyUser", { ns: "messages" }), 5);
        setTrialDetail(res);
        setVerified(true);
      } else if (res?.error) {
        ShowMessage("error", res.description, 5);
      }

      setLoading(false);
    });
  };

  const Detail = () => {
    const keys = [t("trialLink"), t("trialDate")];
    const values = [
      <div style={{ wordWrap: "break-word" }}>
        <a href={trialDetail?.trialLink} target="_blank" rel="noreferrer">
          {trialDetail?.trialLink}
        </a>
      </div>,
      <div>{trialDetail?.trialTime} &nbsp;&nbsp;00:00-23:59</div>,
    ];

    return (
      <>
        <div className="message hint">{t("verifySucHint")}</div>
        <div className="message">
          <div>{t("verifySucMessage")}&nbsp;</div>
          <div className="email">{trialDetail?.sendTo}</div>
        </div>
        <div className="detail">
          <div style={{ display: "flex" }}>
            <div className="keys">{keys[0] + t("colon")}</div>
            <div className="values">{values[0] ? values[0] : "-"}</div>
          </div>
          <div style={{ display: "flex", marginTop: "8px" }}>
            <div className="keys">
              <div className="key">{keys[1] + t("colon")}</div>
            </div>
            <div className="values">{values[1] ? values[1] : "-"}</div>
          </div>
        </div>
      </>
    );
  };

  const ApplyButton = () => (
    <div className="button">
      <Button type="primary" loading={loading} onClick={handleVerify}>
        {t("apply", { ns: "components" })}
      </Button>
    </div>
  );

  return (
    <div className="freeTrialContainer">
      <Card style={cardStyle}>
        <div className="logo">
          <img
            src="/images/Title_logo.png"
            alt="HERCULES & SecSBOM logo"
            style={{ width: "200px" }}
          />
        </div>
        <div className="title">{t("binaryAnalysisFreeTrial")}</div>
        <div className="description">{t("freeTrialDes")}</div>
        {verified ? <Detail /> : <ApplyButton />}
      </Card>
    </div>
  );
};
