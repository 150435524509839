import { capitalizeFirstLetter, generateRoutes } from "utils/formatter";

function Module(name, key, apiUrl) {
  this.name = name;
  this.route = generateRoutes(this.name);
  this.api = apiUrl || capitalizeFirstLetter(name);
  this.key = key;
  this.isChild = true;
}

export const trialApply = new Module("trial apply", "1");
export const freeTrial = new Module("free trial", "2");
export const exportReport = new Module("export report", "3");
