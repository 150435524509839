import React, { useRef, useEffect } from "react";
import { Button } from "antd";
import { getRandomInt } from "utils/formatter";
import { ReloadOutlined } from "@ant-design/icons";

import "./captcha.less";

export default function Captcha({
  captchaValue = "",
  onClickReset = () => {},
}) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = 100;
    canvas.height = 32;

    const ctx = canvas.getContext("2d");

    ctx.fillStyle = "#e1f4fa";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.textAlign = "start";
    ctx.textBaseline = "top";
    ctx.font = "italic 20px Arial";

    let x = 0;
    // render captch value
    for (let i = 0; i < captchaValue.length; i++) {
      // x-axis add from 17 to 21
      x += getRandomInt(17, 21);
      ctx.fillStyle = "#000000";
      ctx.fillText(captchaValue[i], x, getRandomInt(0, canvas.height / 2.5));
    }

    // render 3 to 5 lines
    const lineNum = getRandomInt(3, 5);
    for (let i = 0; i < lineNum; i++) {
      ctx.beginPath();
      ctx.moveTo(getRandomInt(0, 130), getRandomInt(0, 40));
      ctx.lineTo(getRandomInt(0, 130), getRandomInt(0, 40));
      ctx.strokeStyle = "#8c8c8c";
      ctx.stroke();
    }

    // render 3 to 5 circles
    const circleNum = getRandomInt(3, 5);
    for (let i = 0; i < circleNum; i++) {
      ctx.beginPath();
      ctx.arc(getRandomInt(10, 120), getRandomInt(5, 35), 2, 0, 2 * Math.PI);
      ctx.strokeStyle = "#8c8c8c";
      ctx.stroke();
    }
  }, [captchaValue]);

  return (
    <div className="captchaCode">
      <canvas ref={canvasRef}></canvas>
      <Button
        type="link"
        icon={<ReloadOutlined style={{ fontSize: "18px" }} />}
        onClick={onClickReset}
      />
    </div>
  );
}
