import { message } from "antd";

function ShowMessage(
  type = "", // success error warning info warn loading
  description = "",
  duration = 3
) {
  if (description !== "") message[type](description, duration);
  else message[type]("Uncaught Error", duration);
}

export default ShowMessage;
