import React from "react";
import { Navigate } from "react-router-dom";
import { trialApply, freeTrial, exportReport } from "../constants/module";
import { TrialApply } from "pages/TrialApply/trialApply";
import { FreeTrial } from "pages/FreeTrial/freeTrial";
import { ExportReport } from "pages/ExportReport/exportReport";
import "../default.less";

export const routes = [
  {
    path: trialApply.route,
    element: <TrialApply />,
  },
  {
    path: freeTrial.route,
    element: <FreeTrial />,
  },
  {
    path: exportReport.route,
    element: <ExportReport />,
  },
  {
    path: "*",
    element: <Navigate to={trialApply.route} replace={true} />,
  },
];
